import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { any, func, object, string } from "prop-types"
import { Row, Col, Button, Form } from "antd"
import { ArrowLeftOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons"
import moment from "moment"
import { FormStepsHeader } from "./FormStepsHeader"
import { FormSectionBuilder } from "./FormSectionBuilder"
import { PatientProfileSummary } from "../PatientProfile/PatientProfileSummary"
import "./styles.scss"
import { PfizerAeFormPreview } from "../PatientProfile/AdverseEventForm/pfizerAeFormPreview"
import { PFIZER_AE_FORM_PREVIEW_SECTION } from "../../../constants/common"

export const DynamicForm = (props = {}) => {
  let [form] = Form.useForm()
  if (props?.customForm) {
    form = props?.customForm
  }
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const nonFormSections = ["review_info", PFIZER_AE_FORM_PREVIEW_SECTION]
  const {
    pageTitle = "",
    formDetails = {},
    optionsData = {},
    saveCallback,
    savedData = {},
    fetchOptionsBasedOnUserSelection,
    formType = "",
    stepsFilterSpecialityId = "",
    stepsFilterSchemeId = "",
    customUploadFileCallback = null,
    uploadFileParams = {},
    customBackBtnAction = null,
    backBtnText = "Previous",
    formSectionBuilderWidth = 6,
    showFormFooter = true,
    trackFormChanges = null,
    submitValidationCheckFunction = null,
    showStepNumber = false,
    customGoBackBtn = null,
    formFooterClassname = "form-footer",
    stepsFilterCustomSteps = {},
  } = props
  const scrollToTop = () => {
    const scrollableElement = document?.getElementById("scrollable-form-section-fields-container") || ""
    if (scrollableElement) {
      scrollableElement.scrollIntoView()
    }
    return false
  }
  console.log("uploadFileParams", uploadFileParams)
  const isSingleSectionForm = () => formDetails?.sections?.length === 1
  const hasSingleSection = isSingleSectionForm()
  const disableFormButtons = hasSingleSection && formType !== "missingDocuments"
  const [formData, setFormData] = useState({})
  const [actionBtnsDisabled, disableActionBtns] = useState(disableFormButtons)
  // const enableActionBtns = () => disableActionBtns(false)
  const [editFlowDuringCreate, setEditFlowDuringCreate] = useState(false)
  const [currentSectionNo, setCurrentSectionNo] = useState(0)
  const [stateVars, setStateVars] = useState({})
  useEffect(() => {
    if (JSON.stringify(formData) === "{}" && JSON.stringify(savedData) !== "{}") {
      setFormData(savedData)
    }
    if (
      formDetails?.state_vars &&
      JSON.stringify(formDetails.state_vars) !== "{}" &&
      JSON.stringify(stateVars) === "{}"
    ) {
      setStateVars(formDetails.state_vars)
    }
  }, [savedData, formDetails])
  const stateVarActions = ({ key = "", action = "" }) => {
    let stateVar = ""
    stateVar = stateVars[key] || ""
    let updatedVar = ""
    let updatesStateVars = {}
    updatesStateVars = {
      ...stateVars,
    }
    if (stateVar) {
      const currentVal = stateVar?.val || ""
      const maxVal = stateVar?.max_val || ""
      if (action === "increment") {
        if (currentVal < maxVal) {
          updatedVar = {
            ...stateVar,
            val: currentVal + 1,
          }
          updatesStateVars = {
            ...updatesStateVars,
            [key]: updatedVar,
          }
          setStateVars(updatesStateVars)
        }
      }
    }
  }
  const goToPreviousSection = () => {
    setCurrentSectionNo(currentSectionNo - 1)
    scrollToTop()
  }
  const goToNextSection = () => {
    setCurrentSectionNo(currentSectionNo + 1)
    scrollToTop()
  }
  const getCurrentSectionKey = () => {
    const sections = formDetails?.sections || []
    const currentSection = sections[currentSectionNo] || {}
    return currentSection?.name || ""
  }
  const getCurrentSectionInformation = () => {
    const sections = formDetails?.sections || []
    const currentSection = sections[currentSectionNo] || {}
    return currentSection?.information || ""
  }
  const getCurrentSectionDetails = () => {
    const sections = formDetails?.sections || []
    const currentSection = sections[currentSectionNo] || {}
    return currentSection
  }
  const getCurrentSectionFields = () => {
    const sections = formDetails?.sections || []
    const currentSection = sections[currentSectionNo] || {}
    const currentSectionFields = currentSection.steps || []
    return currentSectionFields
  }
  const isLastSection = () => {
    const sections = formDetails?.sections || []
    const totalSections = sections?.length || 0
    return currentSectionNo === totalSections - 1 || totalSections === 0
  }
  const goToLastSection = () => {
    const sections = formDetails?.sections || []
    const totalSections = sections?.length || 0
    const lastSectionNo = totalSections - 1
    setCurrentSectionNo(lastSectionNo)
    setEditFlowDuringCreate(false)
  }

  const isCurrentSectionLast = isLastSection()
  const saveSectionData = (data) => {
    if (isCurrentSectionLast) {
      disableActionBtns(true)
    }
    const formattedData = Object.keys(data).reduce((acc, dataKey) => {
      let dataVal = ""
      dataVal = data[dataKey]
      acc[dataKey] = dataVal
      if (dataVal && dataVal.length > 0) {
        acc[dataKey] = Array.isArray(dataVal) ? dataVal : dataVal?.trim()
      }
      return acc
    }, {})
    const currentSectionKey = getCurrentSectionKey()
    const updatedFormData = {
      ...formData,
      [currentSectionKey]: formattedData,
    }
    setFormData(updatedFormData)
    if (!isCurrentSectionLast) {
      if (editFlowDuringCreate) {
        goToLastSection()
      } else {
        goToNextSection()
      }
    } else {
      saveCallback(updatedFormData)
    }
  }

  const getInitialValues = () => {
    const currentSectionKey = getCurrentSectionKey()
    const useSavedDataForAe = formType === "adverseEventEditForm" && hasSingleSection
    const initialValues =
      formType === "physicalVerificationForm" ||
      formType === "markPvVerified" ||
      formType === "markPvIncomplete" ||
      useSavedDataForAe
        ? savedData[currentSectionKey]
        : formData[currentSectionKey] || {}
    let formattedInitialValues = ""
    formattedInitialValues = {}
    formattedInitialValues = Object.keys(initialValues || {}).reduce((acc, key) => {
      acc[key] = initialValues?.[key]
      if (acc[key] && moment.isMoment(acc[key])) {
        acc[key] = acc[key].utc(false)
      }
      if (key === "patient_birthdate" && initialValues?.[key]) {
        acc[key] = moment(acc[key]).utc(false)
      }
      return acc
    }, {})
    form.setFieldsValue(formattedInitialValues)
  }

  const isFirstSection = currentSectionNo === 0
  useEffect(() => {
    getInitialValues()
  }, [savedData, formDetails, currentSectionNo, formData])
  const currentSectionKey = getCurrentSectionKey()

  const handleFormFieldsChange = (changedValue) => {
    if (submitValidationCheckFunction) submitValidationCheckFunction(form)
    if (trackFormChanges) trackFormChanges(changedValue)
    disableActionBtns(false)
  }
  const handleFormBackBtn = () => {
    if (customBackBtnAction) {
      customBackBtnAction()
    } else {
      goToPreviousSection()
    }
  }
  const showSubmitButton = formType !== "missingDocuments"
  const showBackButton = !isFirstSection || customBackBtnAction !== null
  const isCurrentSectionNonFormSection = nonFormSections.includes(currentSectionKey)
  return (
    <div className="form-layout-container">
      <div className="white-bg form-layout">
        {pageTitle && (
          <div className="form-header">
            <Row className="form-title-row" align="middle">
              <Col span={6}>
                <Row align="middle">
                  <Col span={1}>
                    <Button
                      type="text"
                      onClick={customGoBackBtn || goBack}
                      icon={<ArrowLeftOutlined className="form-back-btn" />}
                    />
                  </Col>
                  <Col span={11} className="form-title">
                    {pageTitle}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <div className="form-body">
          <FormStepsHeader
            currentSection={currentSectionNo}
            sections={formDetails?.sections || []}
            showTitle
            showStepNumber={showStepNumber}
          />
          <Form
            className="dynamic-form-fields-container"
            form={form}
            layout="vertical"
            onFinish={saveSectionData}
            onFieldsChange={handleFormFieldsChange}
            scrollToFirstError
          >
            <div className="form-fields-container">
              {currentSectionKey === "review_info" && (
                <PatientProfileSummary
                  source="createFlow"
                  data={formData}
                  editCallback={({ editIndex }) => {
                    disableActionBtns(false)
                    setEditFlowDuringCreate(true)
                    setCurrentSectionNo(editIndex)
                  }}
                />
              )}
              {currentSectionKey === PFIZER_AE_FORM_PREVIEW_SECTION && (
                <PfizerAeFormPreview formData={formData} />
              )}
              {!isCurrentSectionNonFormSection && (
                <Row>
                  <Col
                    push={(12 - formSectionBuilderWidth) / 2}
                    span={formSectionBuilderWidth}
                    id="scrollable-form-section-fields-container"
                  >
                    <FormSectionBuilder
                      section={getCurrentSectionDetails()}
                      fields={getCurrentSectionFields()}
                      sectionKey={getCurrentSectionKey()}
                      sectionInformation={getCurrentSectionInformation()}
                      optionsData={optionsData}
                      fetchOptionsBasedOnUserSelection={(name, value) => {
                        fetchOptionsBasedOnUserSelection(name, value, form)
                      }}
                      formType={formType}
                      stepsFilterSpecialityId={stepsFilterSpecialityId}
                      stepsFilterSchemeId={stepsFilterSchemeId}
                      stepsFilterCustomSteps={stepsFilterCustomSteps}
                      formObj={form}
                      uploadFileParams={uploadFileParams}
                      customUploadFileCallback={customUploadFileCallback}
                      stateVarActions={stateVarActions}
                      stateVars={stateVars}
                      fieldSets={formDetails.field_sets || {}}
                    />
                  </Col>
                </Row>
              )}
            </div>
            {showFormFooter && (
              <div className={formFooterClassname}>
                <Row align="middle">
                  <Col push={3} span={6}>
                    <Row className="form-btns-row" align="middle">
                      <Col span={4}>
                        {showBackButton && (
                          <Button
                            onClick={() => handleFormBackBtn()}
                            icon={<LeftOutlined />}
                            className="form-previous-btn"
                            disabled={actionBtnsDisabled}
                          >
                            {backBtnText}
                          </Button>
                        )}
                      </Col>
                      <Col push={4} span={4} className="text-right">
                        {showSubmitButton && (
                          <Button
                            disabled={actionBtnsDisabled}
                            type="primary"
                            htmlType="submit"
                            className="form-submit-btn"
                          >
                            {isCurrentSectionLast ? "Submit" : "Next"}
                            <RightOutlined />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}

DynamicForm.defaultProps = {
  pageTitle: "",
  formDetails: {},
  optionsData: {},
  saveCallback: () => {},
  savedData: {},
  fetchOptionsBasedOnUserSelection: () => {},
  formType: "",
  stepsFilterSpecialityId: "",
  customUploadFileCallback: null,
  uploadFileParams: {},
  customBackBtnAction: null,
  backBtnText: "Previous",
  customForm: null,
}

DynamicForm.propTypes = {
  pageTitle: string,
  // eslint-disable-next-line react/forbid-prop-types
  formDetails: object,
  // eslint-disable-next-line react/forbid-prop-types
  optionsData: object,
  saveCallback: func,
  // eslint-disable-next-line react/forbid-prop-types
  savedData: object,
  fetchOptionsBasedOnUserSelection: func,
  formType: string,
  stepsFilterSpecialityId: string,
  customUploadFileCallback: func,
  // eslint-disable-next-line react/forbid-prop-types
  uploadFileParams: object,
  customBackBtnAction: func,
  backBtnText: string,
  // eslint-disable-next-line react/forbid-prop-types
  customForm: any,
}
