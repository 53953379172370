/**
 *
 * @returns string url
 */
export const fetchCreateDoctorFormUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/get-master-requirements?${queryParamsString}`
/**
 *
 * @returns string url
 */
export const createDoctorUrl = `/crm/master/v1/create-doctor`
/**
 *
 * @returns string url
 */
export const createHospitalUrl = `/crm/master/v1/create-hospital`
/**
 *
 * @returns string url
 */
export const createDistributorUrl = `/crm/master/v1/create-distributor`
/**
 *
 * @returns string url
 */
export const createLabUrl = `/crm/master/v1/create-lab`
/**
 *
 * * @returns string url
 */
export const updateLabUrl = `/crm/master/v1/update-lab`
/**
 *
 * @returns string url
 */
export const editDoctorUrl = `/crm/master/v1/edit-doctor`
/**
 *
 * @returns string url
 */
export const editHospitalUrl = `/crm/master/v1/edit-hospital`
/**
 *
 * @returns string url
 */
export const editDistributorUrl = `/crm/master/v1/edit-distributor`
/**
 *
 * @returns string url
 */
export const getDoctorProfileUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/doctor-profile-details?${queryParamsString}`
/**
 *
 * @returns string url
 */
export const getHospitalProfileUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/hospital-profile-details?${queryParamsString}`
/**
 *
 * @returns string url
 */
export const getDistributorProfileUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/distributor-profile-details?${queryParamsString}`

/**
 *
 * * @returns string url
 */
export const getLabProfileUrl = ({ queryParamsString = "" }) => `/crm/master/v1/lab?${queryParamsString}`

/**
 *
 * @returns string url
 */
export const getAllDoctorsUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/doctor-table?${queryParamsString}`
/**
 *
 * @returns string url
 */
export const getMasterOptionsUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/fetch-master-options?${queryParamsString}`
/**
 *
 * @returns string url
 */
export const getAllHospitalsUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/hospitals?${queryParamsString}`
/**
 *
 * @returns string url
 */
export const getAllDistributorsUrl = ({ queryParamsString = "" }) =>
  `/crm/master/v1/distributors?${queryParamsString}`
/**
 *
 * * @returns string url
 */
export const getAllLabsUrl = ({ queryParamsString = "" }) => `/crm/master/v1/labs?${queryParamsString}`
/**
 *
 * @returns string url
 */
export const doctorConsentRetriggerUrl = `/crm/master/v1/re-trigger-doctor-consent-flow`
/**
 *
 * @returns string url
 */
export const uploadDoctorConsentUrl = `/crm/master/v1/upload-manual-doctor-consent`
/**
 *
 * @returns string url
 */
export const exportDashboardUrl = () => `/master/export`
