import React from "react"
import ReactDOM from "react-dom/client"
import "./utils/polyfill"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import "./index.scss"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"

// redux
import { persistor, store } from "./redux/store"

// eslint-disable-next-line no-undef
Raven?.config("https://d1cff4d4ce4c4ab98bbb766bacf8cdf9@sentry.1mg.com/279", {
  tracesSampleRate: 0.5,
}).install()
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
)
